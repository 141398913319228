import { FC, useContext } from 'react';
import styled from 'styled-components';
import BlockContainer from './BlockContainer';
import useFetchDocument from '../hooks/useFetchDocument';
import { ARTICLE_SCHEMA_ID } from '../Settings';
import Link from './Link';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import useFetchMedia from '../hooks/useFetchMedia';
import Image from './Image';

interface BlockInternalLinkItemProps {
  link: any
}

interface BlockInternalLinksWrapperProps {
  children: JSX.Element | JSX.Element[]
}

const BlockLinks = styled.ul`
  margin: 0 0 30px;
  padding: 0;
  list-style: none;
  float: left;
  overflow: hidden;
  width: 100%;
  min-height: 200px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px 15px;
`;

const ListLink = styled(Link)`
  display: block;
  text-decoration: none;
`;

const LinkItem = styled.li`
  float: left;
  width: 100%;
  position: relative;
  -webkit-tap-highlight-color: ${p => p.theme.accentColor}10;
  cursor: pointer;
`;

const LinkImage = styled(Image)`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
  background-color: #333;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 150px;
    width: 100%;
    height: 50px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    border-radius: 0 0 4px 4px;
    z-index: 0;
  }
`;

const LinkTitle = styled.h2`
  font-weight: 700;
  font-size: .9em;
  margin-bottom: 5px;
  display: block;
  --max-lines: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: ${p => p.theme.textColor};
`;

/**
 * Render a PDF block container
 * @returns {JSX.Element} Component template
 */
export const BlockInternalLinksWrapper: FC<BlockInternalLinksWrapperProps> = ({children}) => {
  return (
    <BlockContainer>
      <BlockLinks>
        {children}
      </BlockLinks>
    </BlockContainer>
  )
}

/**
 * Render an internal link block
 * @returns {JSX.Element} Component template
 */
 export const BlockInternalLinkItem: FC<BlockInternalLinkItemProps> = ({link}) => {
  const { currentLanguage } = useContext(AppContext);
  const [data] = useFetchDocument(link?.document_id || link?.to_document_id);
  const [itemMedia] = useFetchMedia(data?.content?.general?.image);

  if (data?.schema_id !== ARTICLE_SCHEMA_ID) { 
    return null; 
  }

  return (
    <LinkItem>
      <ListLink id={data?.id} presentationType={"article"}>
        <>
          {(!(itemMedia?.filters?.brightness === 0)) && <LinkImage media={link?.content?.image?.mediaId ? link.content?.image : itemMedia}/>}
          <LinkTitle>{link?.content?.title?.length > 0 ? link?.content?.title : getTranslation(data?.title, currentLanguage)}</LinkTitle>
        </>
      </ListLink>
    </LinkItem>
  );
}