import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Image from './Image';
import useFetchMedia from '../hooks/useFetchMedia';
import { ReactComponent as VideoIcon } from "../assets/icon_video.svg";
import { ReactComponent as AudioIcon } from "../assets/icon_audio.svg";
import { LightboxItem } from './LightboxModal';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import { parseCredits } from '../common/Media';
//import { ReactComponent as PhotoIcon } from "../assets/icon_photo.svg";

interface MediaGalleryItemProps {
  media: any,
  title?: string,
  subtitle?: string,
  cellSize?: any,
  textPosition?: string,
  titleColor?: string,
  subtitleColor?: string,
  titleSize?: string,
  subtitleSize?: string,
  textBackground?: string,
  useOriginalMedia?: boolean
}

const ListElement = styled.li.attrs((p: any) => ({
  rows: p.rows || 1,
  columns: p.columns || 1
}))`
width: 100%;
height: 100%;
position: relative;
overflow: hidden;
border-radius: 8px;
overflow: hidden;
box-shadow: ${p => p.theme.elementShadow};
grid-column-end: span ${p => p.columns};
grid-row-end: span ${p => p.rows};

& a {
  width: 100%;
  height: 100%;
  display: block;
}
`

const ListTitleWrapper = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  padding: 20px 30px;
  display: inline-table;
  width: 100%;
  word-break: break-word;
  pointer-events: none;

  &.top-left {
    top: 0;
    left: 0;
  }

  &.top-center {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  &.top-right {
    top: 0;
    left: unset;
    right: 0;
    text-align: right;
  }

  &.mid-left {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  &.mid-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &.mid-right {
    top: 50%;
    transform: translateY(-50%);
    left: unset;
    right: 0;
    text-align: right;
  }

  &.bottom-left {
    bottom: 0;
    left: 0;
  }

  &.bottom-center {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  &.bottom-right {
    bottom: 0;
    left: unset;
    right: 0;
    text-align: right;
  }

  &.bg-shadow span {
    text-shadow: 0 0 4px #000, 0 -5px 20px rgba(0,0,0,.5), 0 0 40px rgba(0,0,0,.5);
  }

  &.bg-block span {
    background: #000;
    padding: 5px 10px;
    display: inline-block;
  }

  &.bg-gradient {
  }
`

const ListTitle = styled.span.attrs((p: any) => ({
  size: p.size || "1.5em",
  color: p.color || "#ffffff"
}))`
  display: block;
  font-size: ${p => p.size};
  color: ${p => p.color};
  font-weight: bold;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
  overflow: hidden;
  --max-lines: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const ListSubTitle = styled.span.attrs((p: any) => ({
  size: p.size || "1em",
  color: p.color || "#ffffff"
}))`
  display: block;
  font-size: ${p => p.size};
  color: ${p => p.color};
  text-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
`

const ListImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ListTypeIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 40px 40px 0 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${p => p.theme.accentColor};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  & svg {
    color: ${p => p.theme.textColor};
    width: 32px;
    height: 32px;
    padding-left: 5px;
  }
`

/**
 * Render an item for the list view
 * @returns {JSX.Element} Component template
 */
const MediaGalleryItem: FC<MediaGalleryItemProps> = ({media, title, subtitle, cellSize, textPosition, titleColor, subtitleColor, titleSize, subtitleSize, textBackground, useOriginalMedia}) => {
  const { currentLanguage } = useContext(AppContext);
  const [mediaIcon, setMediaIcon] = useState<JSX.Element>();
  const [itemMedia] = useFetchMedia(media, { includeOriginalUrl: useOriginalMedia });
  const [mediaTitle, setMediaTitle] = useState<string>();
  const [itemSubtitle, setItemSubtitle] = useState<any>(null);
  const [mappedCellSize, setMappedCellsize] = useState<any>(null);

  //const [mediaDescription, setMediaDescription] = useState<string>();
  const [mediaCredits, setMediaCredits] = useState<string>();

  // Get media icon
  useEffect(() => {
    if (itemMedia?.media_type) {
      switch(itemMedia?.media_type) {
        case "media":
        case "video":
        case "model3d":
        case "sketchfab":
          setMediaIcon(<VideoIcon/>);
          break;
        case "image":
        case "dm":
          //setMediaIcon(<PhotoIcon/>);
          break;
        case "document":
          //setMediaIcon(<PdfIcon/>);
          break;
        case "audio":
          setMediaIcon(<AudioIcon/>);
          break;
      }
    }
  }, [itemMedia]);

  // Set media captions and credits
  useEffect(() => {
    setMediaTitle(getTranslation(title, currentLanguage)?.length > 0 ? getTranslation(title, currentLanguage) : getTranslation(itemMedia?.title, currentLanguage));
    setItemSubtitle(getTranslation(subtitle, currentLanguage)?.length > 0 ? getTranslation(subtitle, currentLanguage) : null);
    //setMediaDescription(getTranslation(itemMedia?.description, currentLanguage || ""));
    setMediaCredits(parseCredits(itemMedia?.credits));
  }, [itemMedia, title, subtitle, currentLanguage]);

  // Get the item cellsize. Can be received as a string ("2x3") or an object ({columns: 2, rows: 3})
  useEffect(() => {
    if (typeof cellSize === "string") {
      setMappedCellsize({
        columns: Number(cellSize?.split("x")?.[0] || 1),
        rows: Number(cellSize?.split("x")?.[1] || 1)
      });
    } else {
      // The cellsize values were switched in the old version.
      setMappedCellsize({
        columns: Number(cellSize?.rows || 1),
        rows: Number(cellSize?.columns || 1)
      });
    }
  }, [cellSize]);

  if (!itemMedia?.url) {
    return (
      <ListElement>
        <ListImage/>
      </ListElement>
    );
  }

  return (
    <ListElement rows={mappedCellSize?.rows || 1} columns={mappedCellSize?.columns || 1}>
      <LightboxItem media={itemMedia} caption={mediaTitle} credits={mediaCredits}>
        <ImageWrapper className="media">
          <ListImage media={itemMedia}/>
        </ImageWrapper>
      </LightboxItem>
      {mediaIcon && (<ListTypeIcon>{mediaIcon}</ListTypeIcon>)}
      <ListTitleWrapper className={`${textPosition} bg-${textBackground}`}>
        {itemSubtitle && (<ListSubTitle size={subtitleSize} color={subtitleColor}>{itemSubtitle}</ListSubTitle>)}
        <ListTitle size={titleSize} color={titleColor}>{mediaTitle}</ListTitle>
      </ListTitleWrapper>
    </ListElement>
  );
}

export default MediaGalleryItem;
