import { FC, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import CheckIcon from "../assets/icon_check.svg";

interface BlockTaskQuizProps {
  taskData: any,
  completeCallback: (wasCorrect: boolean, response?: string) => void
}

const Question = styled.span`
  font-weight: bold;
  display: block;
  margin-bottom: 1.5vw;
  font-size: 1.2em;
`;

const Answers = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
`;

const Answer = styled.li`
  clear: both;
  padding: 0.5vw 0;
  line-height: 2vwpx;
  min-height: 2vwpx;
  cursor: pointer;
  font-size: 1.2em;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    width: 2vw;
    height: 2vw;
    border-radius: .2vw;
    display: block;
    background-color: ${p => p.theme.accentColor};
    float: left;
    clear: both;
    margin-right: 20px;

    @media (max-width: 1200px) {
      width: 3vw;
      height: 3vw;
    }
  }



  &:active::before {
    opacity: .8;
  }

  &.checked::before {
    background-image: url(${CheckIcon});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 20px;
  }
`;

/**
 * Render a quiz task
 * @returns {JSX.Element} Component template
 */
const BlockTaskQuiz: FC<BlockTaskQuizProps> = ({taskData, completeCallback}) => {
  const answersRef = useRef<HTMLUListElement>(null);

  // Clear all checkboxes
  const clearAnswers = useCallback(() => {
    if (answersRef.current) {
      answersRef.current.querySelectorAll("li")?.forEach(item => {
        item?.classList?.remove("checked");
      })
    }
  }, []);


  // Handle answer click
  const processAnswer = useCallback((e, index) => {
    // Clear all checkboxes
    clearAnswers();

    // Check the selected checkbox
    e?.target.classList?.add("checked");

    // Check answer
    setTimeout(() => {
      if (taskData?.answers?.[index]?.correct) {
        //console.log("CORRECT ANSWER!");
        completeCallback(true, taskData?.answers?.[index]?.response);
      } else {
        //console.log("WRONG ANSWER!")
        completeCallback(false, taskData?.answers?.[index]?.response);
      }
    }, 300);
  }, [taskData?.answers, completeCallback, clearAnswers]);

  useEffect(() => {
    taskData && clearAnswers();
  }, [taskData, clearAnswers]);

  return (
    <>
      <Question>{taskData?.question}</Question>
      <Answers ref={answersRef}>
        {taskData?.answers?.map((answer: any, i: number) => {
          return <Answer key={`answer${i}`} onClick={e => processAnswer(e, i)}>{answer.answer}</Answer>
        })}
      </Answers>
    </>
  );
}

export default BlockTaskQuiz;