import {createContext} from "react";

interface AppProviderProps {
  deviceId?: number,
  deviceData: any,
  deviceMenu: any,
  deviceLanguages: any,
  currentLanguage: string,
  previewIsActive: boolean,
  globalVolume: number,
  globalFontSize: number,
  mediaIsPlaying: boolean,
  theme: {
    backgroundColor: string,
    navigationColor: string,
    textColor: string,
    accentColor: string,
    scaleFactor: number
  },
  setTheme: any,
  mediaPopup: {
    isOpen: boolean,
    mediaType?: string | undefined,
    mediaCaption?: string | undefined
  },
  popup: {
    icon?: string,
    confetti?: boolean,
    title?: string,
    body?: string,
    buttons?: [
      title?: string,
      onClick?: () => void
    ]
  },
  scheduledIds: number[]|undefined,
  setScheduledIds: any,
  showScrollToTopButton: boolean,
  setShowScrollToTopButton: any,
  idleTime: number,
  setIdleTime: any,
  hideTopNavigation: boolean,
  setHideTopNavigation: any,
  scrollHistory: any,
  setScrollHistory: any,
  overlayContent: any,
  setDeviceId: any,
  setDeviceData: any,
  setDeviceMenu: any,
  setDeviceLanguages: any,
  setCurrentLanguage: any,
  setGlobalFontSize: any,
  setPreviewIsActive: any,
  setMediaPopup: any,
  setMediaIsPlaying: any,
  setOverlayContent: any,
  setGlobalVolume: any,
  hideHeader: boolean,
  setHideHeader: any,
  resetTimer: any,
  setPopup: any,
  forceUseOriginalMedia: boolean
}

export const AppContext = createContext<Partial<AppProviderProps>>({});