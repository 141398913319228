import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { getPresentationComponent } from '../common/Common';
import { AppContext } from '../contexts/AppContext';
import HomeScreenView from './HomeScreen';
import ErrorMessageView from '../components/ErrorMessage';
import LoadingOverlay from '../components/LoadingOverlay';
import { fetchDocument } from '../services/Api';
import { DEVICE_SCHEMA_ID } from '../Settings';
import useFetchDocument from '../hooks/useFetchDocument';
import useFetchColorScheme from '../hooks/useFetchColorScheme';

interface DeviceHandleProps {
  previewData?: any;
}

/**
 * Renders a device screen based on the device ID in the URL.
 */
const DeviceHandle: FC<DeviceHandleProps> = ({ previewData = null }) => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const navigate = useNavigate();
  const { setDeviceData, setDeviceId, setIdleTime, scheduledIds, setShowScrollToTopButton } = useContext(AppContext);
  const [currentComponent, setCurrentComponent] = useState<JSX.Element | null>(null);
  const { t } = useTranslation();
  const [data, error, isLoading] = useFetchDocument(deviceId, previewData, true);
  useFetchColorScheme({colorDocumentId: previewData?.layout?.theme?.[0]?.to_document_id, colorObject: previewData?.colors});

  // Lagre deviceId
  useEffect(() => {
    if (!isNaN(Number(deviceId))) {
      setDeviceId(deviceId);
    }
  }, [deviceId, setDeviceId]);

  // Restart the app after 10 seconds if an error occurs
  useEffect(() => {
    if (error && !isNaN(Number(deviceId))) {
      console.log("An error occurred. Restarting app in 10 seconds...");
      setTimeout(() => {
        navigate(`/device/${deviceId}/`);
        window.location.reload();
      }, 10000);
    }
  }, [error, deviceId, navigate]);

  const setIdleTimer = useCallback((idleTime: string) => {
    const idleMap: Record<string, number> = {
      "disabled": 0,
      "1m": 60000,
      "2m": 120000,
      "3m": 180000,
      "4m": 240000,
      "5m": 300000
    };

    setIdleTime(idleMap[idleTime] || 60000);
  }, [setIdleTime]);

  // Update view
  useEffect(() => {
    let isMounted = true;

    (async () => {
      if (!data) return;

      setDeviceData(data?.content);
      setIdleTimer(data?.content?.idleMode?.idleTime);
      setShowScrollToTopButton(data?.content?.accessibility?.showScrollToTop || false);

      const contentId = scheduledIds?.[0] ?? data?.content?.presentations?.list?.[0]?.to_document_id ?? null;

      // Should the home screen be shown?
      if (!scheduledIds?.[0] && data?.content?.general?.startScreen !== "first") {
        setCurrentComponent(<HomeScreenView data={data?.content} layout={data?.content?.general?.startScreen} />);
      } else if (contentId) {
        try {
          const presentation = await fetchDocument(contentId);
          if (isMounted) {
            const presentationType = presentation?.content?.general?.presentationType || "article";
            const titleOverride = data?.content?.general?.showTitle ? data?.content?.general?.title : undefined;

            setCurrentComponent(getPresentationComponent(presentationType, presentation?.content, titleOverride));
          }
        } catch (error) {
          if (isMounted) {
            console.error("Failed to fetch presentation:", error);
          }
        }
      }
    })();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [data?.content, scheduledIds]);

  // Data validation
  if (error || data?.status === "error") {
    return <ErrorMessageView title={t("deviceNotFound.title")} body={t("deviceNotFound.body")} />;
  }

  if (!previewData && !isLoading && deviceId && (data?.status && !data?.title)) {
    return <ErrorMessageView title={t("deviceNotFound.title")} body={t("deviceNotFound.body")} />;
  }

  if (data?.schema_id && data?.schema_id !== DEVICE_SCHEMA_ID) {
    return <ErrorMessageView title={t("presentationNotFound.title")} body={t("presentationNotFound.body")} />;
  }

  if (!previewData && !isLoading && (!deviceId || isNaN(Number(deviceId)))) {
    return <ErrorMessageView title={t("missingId.title")} body={t("missingId.body")} />;
  }

  if (data?.status === "draft") {
    return <ErrorMessageView title={t("presentationNotPublished.title")} body={t("presentationNotPublished.body")} redirect />;
  }

  return (
    <>
      <LoadingOverlay isOpen={!data?.status} />
      {currentComponent}
    </>
  );
};

export default DeviceHandle;
