import { FC, useCallback, useContext, useEffect, useState } from "react";
import i18n from "i18next";
import Routes from "./Routes";
import Kulturio from '@ekultur/kulturio-bridge';
import { ThemeProvider } from "styled-components";
import useDynamicIdleTimer from "./hooks/useDynamicIdleTimer";
import { AppContext } from "./contexts/AppContext";
import { DEFAULT_SCREEN_TIMEOUT, KULTURIO_ENV } from "./Settings";
import { useLocation, useNavigate } from "react-router-dom";
import packageJson from '../package.json';
import Popup from "./components/Popup";
import ContentUpdateButton from "./components/ContentUpdateButton";
import { useContentSchedule } from "./hooks/useContentSchedule";
import useFetchColorScheme from "./hooks/useFetchColorScheme";

/**
 * App
 * @returns {JSX.Element} Component template
 */
const App = () => {
  // AppContext states
  const navigate = useNavigate();
  const location = useLocation();
  const [overlayContent, setOverlayContent] = useState<any>(null);
  const [customScale, setCustomScale] = useState<number>(1);
  const [popup, setPopup] = useState<any>(null);
  const [hideHeader, setHideHeader] = useState<boolean>(false);
  const [globalFontSize, setGlobalFontSize] = useState(1);
  const [globalVolume, setGlobalVolume] = useState<number>(.8);
  const [showScrollToTopButton, setShowScrollToTopButton] = useState<boolean>(false);
  const [idleTime, setIdleTime] = useState<number>(DEFAULT_SCREEN_TIMEOUT);
  const [forceUseOriginalMedia, setForceUseOriginalMedia] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n?.resolvedLanguage || "no");
  const [previewIsActive, setPreviewIsActive] = useState<boolean>(false);
  const [deviceId, setDeviceId] = useState<number|undefined>();
  const [deviceData, setDeviceData] = useState<any>(undefined);
  const [deviceMenu, setDeviceMenu] = useState<any>(null);
  const [deviceLanguages, setDeviceLanguages] = useState<string[]>();
  const [mediaIsPlaying, setMediaIsPlaying] = useState<boolean>(false);
  const [scrollHistory, setScrollHistory] = useState<any>({});
  const [scheduledIds, setScheduledIds] = useState<number[]|undefined>(undefined);
  const [theme, setTheme] = useState<any>({});

  // Set design theme
  const updateTheme = useCallback(() => {
    let scaleFactor: number;

    if (window.innerWidth > window.innerHeight) {
      scaleFactor = window.innerWidth / 1400;
    } else {
      scaleFactor = window.innerHeight / 1400;
    }

    document.body.style.fontSize = `${customScale * scaleFactor * globalFontSize * 16}px`;

    setTheme({
      backgroundColor: "#1E2A36",
      textColor: "#ffffff",
      accentColor: "#5b92e5",
      navigationColor: '#ffffff',
      elementShadow: "0 4px 8px rgb(0 0 0 / 10%)",
      blockWidth: window.innerWidth > window.innerHeight ? "80%" : "88%",
      blockMargin: `${customScale * scaleFactor * 40}px`,
      scaleFactor: scaleFactor
    });
  }, [customScale, globalFontSize]);

  // Add custom style if provided in Kulturio desktop config
  useEffect(() => {
    const customStyle = Kulturio.getConfig("interface.style");

    if (customStyle && customStyle?.length > 0) {
      const stylesElement = document.createElement("style");
      stylesElement.innerHTML = customStyle;
      document.head.append(stylesElement);
    }
  }, []);

  // Support setting custom scale in the Kulturio desktop config
  useEffect(() => {
    if (Kulturio.inKulturioApp()) {
      setCustomScale(Number(Kulturio.getConfig("interface.scale")) || 1);
      updateTheme();
    }
  }, [customScale, updateTheme]);

  // Get deviceId from config.json, if run in the Kulturio desktop application
  useEffect(() => {
    if (deviceId) { return; }
    const clientDeviceId = Kulturio.getConfig("interface.deviceId");
    if (clientDeviceId) {
      setDeviceId(clientDeviceId);
      navigate(`/device/${clientDeviceId}/`);
    }
  }, [setDeviceId, navigate, deviceId]);

  // Should 
  useEffect(() => {
    if (Kulturio.inKulturioApp()) {
      const hideHeader = Kulturio.getConfig("interface.hideHeader") || false;
      setHideHeader(hideHeader);
    }
  }, []);

  // Get global volume from config.json, if run in the Kulturio desktop application
  useEffect(() => {
    if (Kulturio.inKulturioApp()) {
      const defaultVolume = Kulturio.getConfig("interface.defaultVolume") || 0.8;
      defaultVolume && setGlobalVolume(defaultVolume)
    }
  }, [setGlobalVolume]);

  // Should videos be forced loaded as original files
  useEffect(() => {
    if (Kulturio.inKulturioApp()) {
      const forceOriginal = Kulturio.getConfig("interface.forceOriginalMedia") || false;
      forceOriginal && setForceUseOriginalMedia(forceOriginal)
    }
  }, [setGlobalVolume]);

  // Run on startup
  useEffect(() => {
    // Disable right click
    document.addEventListener('contextmenu', event => event.preventDefault());

    // Listen for remote commands from the Kulturio Remote app
    document.addEventListener("remoteCommand", (data: any) => {
      switch(data?.detail?.command) {
        case "mute":
          setIsMuted(true);
          setGlobalVolume(0)
          break;
        case "unmute":
          setIsMuted(false);
          const defaultVolume = Kulturio.getConfig("interface.defaultVolume") || 0.8;
          setGlobalVolume(defaultVolume)
          break;
        default:
          console.error(`Unsupported command received: ${data.detail.command}`);
          break;
      }
    });
  }, []);

  // Listen for websocket commands - if activated in the desktop app config file
  useEffect(() => {
    if (!Kulturio.inKulturioApp()) { return; }
    // Connect with websocket server
    const websocketServer = Kulturio.getConfig("interface.websocketServer");
    if (!websocketServer) { return; }
    Kulturio.socketInit(websocketServer, { 
      onOpen: () => { 
        console.log("Websocket connection established!"); 
      }
    });

    // Listen for incoming websocket messages
    document.addEventListener("socketMessage", (data: any) => {
      const command = data?.detail?.msg;
      console.log(`Websocket command received: ${command}`)
      Kulturio.executeCommand(command);
    });
  }, []);

  // Scale window based on browser width
  useEffect(() => {
    updateTheme();
    window.addEventListener('resize', () => {
      updateTheme();
    })
  }, [updateTheme]);

  // Set language on startup
  useEffect(() => {
    if ((deviceLanguages || [])?.length > 0) {
      setCurrentLanguage(deviceLanguages?.[0] || "no");
    } else {
      setCurrentLanguage("no");
    }
  }, [deviceLanguages]);

  // Update i18n language on user update
  useEffect(() => {
    i18n?.changeLanguage(currentLanguage);
  }, [currentLanguage, setCurrentLanguage]);

  // Run on startup
  useEffect(() => {
    // Setup theme colors
    document.body.style.backgroundColor = theme.backgroundColor;
    document.body.style.color = theme.textColor;

    // Disable right click
    document.addEventListener('contextmenu', event => event.preventDefault());
  }, [theme]);

  /**
   * Cancel idle timer when activity is tracked
   * @param e Event
   */
  const onIdleTimerActive = (resetTimer: () => void) => {
    resetTimer();
    !previewIsActive && Kulturio?.addStatistics("uniqueVisits", 1);
  }

  /**
   * Reset app if idle time has been exceeded.
   * This function is cancelled if preview mode is running
   */
  const onIdleTimerIdle = useCallback((resetTimer: () => void) => {
    //console.log("Idle timer exceeded!");
    if (previewIsActive || !deviceId || isNaN(Number(deviceId)) || mediaIsPlaying) { 
      //console.log("Idle mode cancelled")
      resetTimer();
      return; 
    }
    
    // Ignore reset if in development mode
    //if (KULTURIO_ENV === "development") { return }

    // Reset language
    if ((deviceLanguages || [])?.length > 0) {
      setCurrentLanguage(deviceLanguages?.[0] || "no");
    }

    // Reset font size
    setGlobalFontSize(1);

    // Scroll to top of page
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Reset scroll history
    setScrollHistory({});

    // Remove overlays
    setPopup(null);
    setOverlayContent(null);

    // Reset global volume
    if (!isMuted) {
      const defaultVolume = Kulturio.getConfig("interface.defaultVolume") || 0.8;
      setGlobalVolume(defaultVolume);
    }

    // Make sure the window is scrollable
    document.body.style.overflow = 'auto';

    // Navigate to main menu
    if (!scheduledIds) {
      navigate(`/device/${deviceId}/`);
    }
  }, [deviceId, deviceLanguages, mediaIsPlaying, previewIsActive, isMuted, scheduledIds, navigate]);

  // Reset if schedule is changed
  useEffect(() => {
    if (!deviceId) { return; }

    if (location.pathname !== `/device/${deviceId}`) {
      navigate(`/device/${deviceId}/`);
    }
    // eslint-disable-next-line
  }, [scheduledIds, deviceId]);

  // Display app version in browser console on startup
  useEffect(() => {
    console.log(`%cKulturio Kiosk v${packageJson?.version}\n%cDeveloped by KulturIT AS\nEnvironment: ${KULTURIO_ENV}`, `font-weight: bold`, ``);
  }, []);

  // Initiate idle timer
  const { reset: resetTimer } = useDynamicIdleTimer({ onIdle: () => onIdleTimerIdle(resetTimer), onActive: () => onIdleTimerActive(resetTimer), timeout: idleTime })
  
  return (
    <AppContext.Provider value={{ theme, setTheme, currentLanguage, setCurrentLanguage, deviceId, deviceData, deviceMenu, deviceLanguages, globalVolume, setGlobalVolume, mediaIsPlaying, setDeviceId, setDeviceData, setDeviceMenu, setDeviceLanguages, previewIsActive, setPreviewIsActive, setMediaIsPlaying, globalFontSize, setGlobalFontSize, resetTimer, overlayContent, setOverlayContent, hideHeader, setHideHeader, popup, setPopup, forceUseOriginalMedia, scrollHistory, setScrollHistory, idleTime, setIdleTime, scheduledIds, setScheduledIds, showScrollToTopButton, setShowScrollToTopButton }}>
      <ThemeProvider theme={theme}>
        {overlayContent && overlayContent}
        <SchedulesAndColors/>
        <Routes/>
        <ContentUpdateButton/>
        <Popup/>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

/**
* Checks the device schedule and updates the AppContext with the schedule id.
* This component should be rendered in the root of the app.
*/
const SchedulesAndColors: FC = () => {
  const { deviceData } = useContext(AppContext);
  useContentSchedule(deviceData?.schedule?.plan);
  useFetchColorScheme();

  return null;
}

export default App;