import { useContext, useEffect, useState } from "react";
import { AppContext } from "../contexts/AppContext";

type ScheduleEntry = {
  views: { reference_key: string; to_document_id: number }[];
  scheduleType: "time" | "trigger" | "advanced";
  days?: string[];
  trigger?: { protocol: "tcp" | "udp" | "websocket" | "remote"; command: string };
  timeRange?: { start: string; end: string };
  name: string;
};

const getDayName = (date: Date) =>
  ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"][
    date.getDay()
  ];

export function useContentSchedule(schedule: ScheduleEntry[] | undefined) {
  const checkSchedule = (): number[] | undefined => {
    if (!schedule || schedule.length === 0) return undefined;

    const now = new Date();
    const nowUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000 + 3600000); // Justerer til GMT+1

    for (const entry of schedule) {
      if (entry.scheduleType === "time" && entry.days?.includes(getDayName(nowUTC))) {
        const startTime = new Date(entry.timeRange?.start || "");
        const endTime = new Date(entry.timeRange?.end || "");

        if (nowUTC >= startTime && nowUTC <= endTime) {
          return entry?.views?.map(view => view.to_document_id) || undefined;
        }
      }
    }
    return undefined;
  };

  const { scheduledIds, setScheduledIds } = useContext(AppContext) || {};
  const [isOverriddenBySchedule, setIsOverriddenBySchedule] = useState(false);


  useEffect(() => {
    const updateContent = () => {
      if (!isOverriddenBySchedule) {
        const newContent = checkSchedule();
        setScheduledIds && setScheduledIds(newContent);
      }
    };

    updateContent();

    const millisUntilNextMinute = (60 - new Date().getSeconds()) * 1000;
    const timeout = setTimeout(() => {
      updateContent();
      const interval = setInterval(updateContent, 60000);
      return () => clearInterval(interval);
    }, millisUntilNextMinute);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [schedule, isOverriddenBySchedule, setScheduledIds]);

  // ✅ **Håndter flere protokoller for å trigge innhold**
  useEffect(() => {
    const handleCommand = (protocol: string, command: string) => {
      const match = schedule?.find(
        (entry) => (entry.scheduleType === "advanced" || entry.scheduleType === "trigger") && entry.trigger?.protocol === protocol && entry.trigger?.command === command
      );

      if (match) {
        setIsOverriddenBySchedule(true);
        setScheduledIds(match?.views?.map(view => view.to_document_id) || undefined);
      }
    };

    const remoteListener = (e: Event) => handleCommand("remote", (e as CustomEvent).detail?.command);
    const websocketListener = (e: Event) => handleCommand("websocket", (e as CustomEvent).detail?.msg);
    const tcpListener = (e: Event) => handleCommand("tcp", (e as CustomEvent).detail?.data);
    const udpListener = (e: Event) => handleCommand("udp", (e as CustomEvent).detail?.data);

    document.addEventListener("remoteCommand", remoteListener);
    document.addEventListener("socketMessage", websocketListener);
    document.addEventListener("tcpMessage", tcpListener);
    document.addEventListener("udpMessage", udpListener);

    return () => {
      document.removeEventListener("remoteCommand", remoteListener);
      document.removeEventListener("socketMessage", websocketListener);
      document.removeEventListener("tcpMessage", tcpListener);
      document.removeEventListener("udpMessage", udpListener);
    };
  }, [schedule, setScheduledIds]);

  // ✅ **Når schedule er ferdig, tilbakestill til standardvisning**
  useEffect(() => {
    if (!isOverriddenBySchedule && scheduledIds !== undefined) {
      const newContent = checkSchedule();
      if (newContent === null) {
        setScheduledIds(undefined);
      }
    }
    //eslint-disable-next-line
  }, [schedule, isOverriddenBySchedule, scheduledIds, setScheduledIds]);

  return { scheduledContentId: scheduledIds?.[0], isOverriddenBySchedule };
}
