import { useContext, useEffect } from "react";
import { AppContext } from "../contexts/AppContext";
import useFetchDocument from "./useFetchDocument";

/**
 * Fetch color scheme from the API
 */
export default function useFetchColorScheme(options?: {colorDocumentId?: number, colorObject?: any}) {
  const { deviceData, setTheme } = useContext(AppContext);
  const [colorData] = useFetchDocument(!options?.colorObject && (options?.colorDocumentId || deviceData?.layout?.theme?.[0]?.to_document_id));

  useEffect(() => {
    const colors = {
      accentColor: options?.colorObject?.accentColor || colorData?.content?.colors?.accentColor || "#5b92e5",
      backgroundColor: options?.colorObject?.backgroundColor || colorData?.content?.colors?.backgroundColor || "#1e2a36",
      navigationColor: options?.colorObject?.navigationColor || colorData?.content?.colors?.navigationColor || "#ffffff",
      textColor: options?.colorObject?.textColor || colorData?.content?.colors?.textColor || "#ffffff"
    };

    const validColors = Object.fromEntries(
      Object.entries(colors).filter(([_, value]) => value !== undefined)
    );

    setTheme(prevTheme => ({ ...prevTheme, ...validColors }));
  }, [colorData, options?.colorObject, setTheme])
}