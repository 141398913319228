import { useIdleTimer } from 'react-idle-timer';
import { useEffect } from 'react';

interface UseDynamicIdleTimerProps {
  timeout: number;
  onIdle: (reset: () => void) => void;
  onActive: (reset: () => void) => void;
}

interface UseDynamicIdleTimerReturn {
  reset: () => void;
  pause: () => void;
}

const useDynamicIdleTimer = ({ timeout, onIdle, onActive }: UseDynamicIdleTimerProps): UseDynamicIdleTimerReturn => {
  const { reset, pause, resume } = useIdleTimer({
    onIdle: () => onIdle(reset),
    onActive: () => onActive(reset),
    timeout: timeout || 86400000,
  });

  useEffect(() => {
    if (timeout !== 86400000) {
      resume();
    } else {
      pause();
    }
  }, [timeout, pause, resume]);

  return { reset, pause };
};

export default useDynamicIdleTimer;
