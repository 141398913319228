import { FC, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import useFetchDocument from '../hooks/useFetchDocument';
import Link from './Link';
import { getContentType } from '../common/Common';
import { getTranslation } from '../common/Translation';

interface ImageMapAnnotationProps {
  documentId: number,
  startPosition: { x: number, y: number },
  textPosition?: { x: number, y: number },
  title?: string,
  subtitle?: string,
  markerColor?: string,
  width: number,
  height: number
}

const Zone = styled.div.attrs((p: any) => ({
  previewIsActive: p.previewIsActive || false,
  x: p.x,
  y: p.y,
  width: p.width || 0,
  height: p.height || 0
}))`
  position: absolute;
  display: block;
  z-index: 10;
  border-radius: 10px;
  left: ${p => p.x}%;
  top: ${p => p.y}%;
  width: ${p => p.width}%;
  height: ${p => p.height}%;
  pointer-events: all;
  border: ${p => p.previewIsActive ? "6px dashed white" : "none"};
  filter: ${p => p.previewIsActive ? "drop-shadow(0px 0px 2px black)" : "unset"};
  cursor: pointer;

  &:active div {
    opacity: .8;
  }
`

const ZoneLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
`;

const AnnotationWrapper = styled.div.attrs((p: any) => ({
  zoom: p.zoom || undefined
}))`
  position: absolute;
  z-index: 10;
  transform-origin: 50% 50%;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 20px;
  max-width: 300px;
  min-width: 250px;
  min-height: 60px;
  zoom: ${p => p.zoom};
  z-index: 15;
  transform: translate(-50%,-50%) translate3d(0, 0, 0);
  pointer-events: all;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
`

const Title = styled.span`
  color: #000;
  font-weight: bold;
  font-size: 1.2em;
  display: block;
  position: relative;
  text-align: center;
`

const Subtitle = styled.span.attrs((p: any) => ({
  accentColor: p.accentColor || undefined
}))`
  color: #000;
  font-size: .9em;
  display: block;
  letter-spacing: .8px;
  text-align: center;

  &::before {
    position: relative;
    content: "";
    background-color: ${p => p.accentColor || p.theme.accentColor};
    height: 5px;
    margin: 8px auto;
    width: 30px;
    display: block;
    color: #a50b30;
  }
`

/**
 * Render an annotation component for the imagemap view
 * @returns {JSX.Element} Component template
 */
const ImageMapAnnotation: FC<ImageMapAnnotationProps> = ({documentId, startPosition, textPosition, width, height, title, subtitle, markerColor}) => {
  const { previewIsActive, currentLanguage } = useContext(AppContext);
  const [contentType, setContentType] = useState<string>("");
  const [scrollStartPos, setScrollStartPos] = useState({ x: null, y: null });
  const [data] = useFetchDocument(documentId);

  /**
   * Save scroll position to state on scroll start
   */
  const handleScrollStart = useCallback((e: any) => {
    setScrollStartPos({ x: e.clientX, y: e.clientY });
  }, []);

  /**
   * Clear scroll position from state when scrolling is finished
   */
  const handleScrollEnd = useCallback((e: any) => {
    setTimeout(() => {
      setScrollStartPos({ x: null, y: null });
    }, 100)
  }, []);

  /**
   * Evaluate if the current click event should be ignored
   * Ignore if scrolling is active
   */
  const evaluateClickAndScroll = useCallback((e: any) => {
    if (scrollStartPos.x !== null && scrollStartPos.y !== null) {
      const xDiff = Math.abs(e.clientX - scrollStartPos.x);
      const yDiff = Math.abs(e.clientY - scrollStartPos.y);
      
      const scrollThreshold = 10;
      if (xDiff > scrollThreshold || yDiff > scrollThreshold) {
        e.preventDefault();
      }
    }
  }, [scrollStartPos]);

  // Get content type of a presentation or article
  useEffect(() => {
    setContentType(getContentType(data))
  }, [data]);

  // Only display if published
  if (data?.status && !data?.status?.includes("published")) { return null; }

  return (
    <Zone x={startPosition.x} y={startPosition.y} width={width} height={height} previewIsActive={previewIsActive} onMouseDown={handleScrollStart} onMouseUp={handleScrollEnd} onTouchStart={handleScrollStart} onTouchEnd={handleScrollEnd}>
      <ZoneLink id={documentId} presentationType={contentType} onClick={evaluateClickAndScroll}>
        <>
          {(getTranslation(title, currentLanguage)?.length > 0) && (
            <AnnotationWrapper style={{left: `${textPosition?.x || 50}%`, top: `${textPosition?.y || 50}%`}}>
              <Title>{getTranslation(title, currentLanguage)}</Title>
              {(getTranslation(subtitle, currentLanguage).length > 0) && (<Subtitle accentColor={markerColor}>{getTranslation(subtitle, currentLanguage)}</Subtitle>)}
            </AnnotationWrapper>
          )}
        </>
      </ZoneLink>
    </Zone>
  );
}

export default ImageMapAnnotation;
