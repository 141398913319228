import {FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';
import Menu from './Menu';
import LanguagePopup from './PopupLanguage';
import { ReactComponent as HomeIcon } from "../assets/icon_home.svg";
import { ReactComponent as BackIcon } from "../assets/icon_back.svg";
import { ReactComponent as MenuIcon } from "../assets/icon_menu.svg";
import { ReactComponent as LargerFontIcon } from "../assets/icon_font_larger.svg";
import { ReactComponent as SmallerFontIcon } from "../assets/icon_font_smaller.svg";
import { ReactComponent as LanguageIcon } from "../assets/icon_language.svg";
import { ReactComponent as VolumeLowIcon } from "../assets/icon_volume_low.svg";
import { ReactComponent as VolumeMidIcon } from "../assets/icon_volume_mid.svg";
import { ReactComponent as VolumeHighIcon } from "../assets/icon_volume_high.svg";
import { ReactComponent as ContrastIcon } from "../assets/icon_contrast.svg";
import VolumeControl from './VolumeControl';
import { getContrastFromColor } from '../common/Common';

interface HeaderProps {
  title?: string,
  backgroundColor?: string,
  hideBackButton?: boolean
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const Wrapper = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: ${p => p.theme.scaleFactor * 30}px ${p => p.theme.scaleFactor * 25}px 0 ${p => p.theme.scaleFactor * 25}px;
  box-sizing: border-box;
  float: left;
  touch-action: none;
  background: linear-gradient(180deg, ${(p) => getContrastFromColor(p.theme?.textColor || "#000", 0.1)} 0,transparent);
  height: ${p => p.theme.scaleFactor * 120}px;
  pointer-events: none;
  display: flex;
  align-items: center;
  align-content: center;

  &.preview a {
    pointer-events: none;
  }

  &.light {
    background: linear-gradient(180deg, rgba(255 255 255 / .1) 0, transparent);

    & h1 {
      /*color: #000;*/
      text-shadow: 1px 1px 5px rgb(255 255 255 / 30%);
    }

    & a {
      filter: drop-shadow(1px 1px 5px rgba(255 255 255 / 30%));
    }
  }

  &.dark {
    background: linear-gradient(180deg, rgba(0 0 0 / .2) 0, transparent);

    & h1 {
      /*color: #000;*/
      text-shadow: 1px 1px 5px rgb(0 0 0 / 50%);
    }

    & a {
      filter: drop-shadow(1px 1px 5px rgba(0 0 0 / 50%));
    }
  }
`;

const Title = styled.h1`
  position: absolute;
  left: 50%;
  width: calc(100% - 300px);
  font-size: 2em;
  font-weight: 700;
  text-shadow: 1px 1px 5px ${(p) => getContrastFromColor(p.theme?.textColor || "#000", 0.3)};
  text-align: center;
  margin: 0 0 5px;
  transform: translateX(-50%);
  max-width: 70%;
  --max-lines: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;

  &.noBackButton {
    /* left: ${p => p.theme.scaleFactor * 100}px;
    transform: translateX(0); */
  }

  &.fadeIn {
    animation: ${fadeIn} 0.2s;
  }
`

const LeftButtonContainer = styled.div`
  position: absolute;
  left: ${p => p.theme.scaleFactor * 65}px;
  text-align: left;

  & a:not(:first-child) {
    margin-left: ${p => p.theme.scaleFactor * 50}px;
  }
`;

const RightButtonContainer = styled.div`
  position: absolute;
  right: ${p => p.theme.scaleFactor * 65}px;
  text-align: right;

  & a:not(:last-child) {
    margin-right: ${p => p.theme.scaleFactor * 50}px;
  }
`;

const Button = styled.a`
  fill: ${p => p.theme?.textColor};
  filter: drop-shadow(1px 1px 5px ${(p) => getContrastFromColor(p.theme?.navigationColor || "#000", 0.5)});
  pointer-events: all;
  cursor: pointer;
  position: relative;
  display: inline-block;

  & svg {
    width: ${p => p.theme.scaleFactor * 35}px;
    height: ${p => p.theme.scaleFactor * 35}px;
    fill: ${p => p.theme.navigationColor || "#ffffff"};
  }

  &:active {
    opacity: .8;
  }

  &&::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
  }
`

/**
 * Render the app header. Consists of a back button, a title and the menu button
 * @returns {JSX.Element} Component template
 */
const Header: FC<HeaderProps> = ({title, backgroundColor, hideBackButton}) => {
  const navigate = useNavigate();
  const { previewIsActive, deviceId, deviceMenu, deviceData, deviceLanguages, globalFontSize, setGlobalFontSize, globalVolume, hideHeader, setMediaIsPlaying } = useContext(AppContext);
  const titleRef = useRef<any>();
  const volumeRef = useRef<any>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [languagePopupOpen, setLanguagePopupOpen] = useState(false);
  const [displayLanguageIcon, setDisplayLanguageIcon] = useState<boolean>(false);
  const [displayFontIcon, setDisplayFontIcon] = useState<boolean>(false);
  const [displayMenuIcon, setDisplayMenuIcon] = useState<boolean>(false);
  const [displayVolumeIcon, setDisplayVolumeIcon] = useState<boolean>(false);
  const [displayHomeIcon, setDisplayHomeIcon] = useState<boolean>(false);
  const [displayContrastIcon, setDisplayContrastIcon] = useState<boolean>(false);
  const [colorMode, setColorMode] = useState<string>("dark");
  const [displayVolumeControl, setDisplayVolumeControl] = useState<boolean>(false);

  /**
   * Go back in history
   */
  const goBack = useCallback(() => {
    setMediaIsPlaying(false);
    navigate(-1);
  }, [navigate, setMediaIsPlaying]);

  /**
   * Go back in history
   */
  const goHome = useCallback(() => {
    deviceId && navigate(`/device/${deviceId}`);
  }, [navigate, deviceId]);

  /**
   * Toggle contrast
   */
  const toggleContrast = useCallback(() => {
    console.log("Contrast button not yet implemented");
  }, []);

  /**
   * Toggle volume control
   */
  const toggleVolume = useCallback(() => {
    setDisplayVolumeControl(true);
  }, []);

  /**
   * Returns true if current page is home
   */
  const isHome = /device\/(\d+)(\/)?$/.test(window?.location?.hash)

  /**
   * Toggle font size
   */
  const toggleFontSize = useCallback(() => {
    if (globalFontSize === 1) {
      setGlobalFontSize(1.2);
    } else {
      setGlobalFontSize(1);
    }
  }, [globalFontSize, setGlobalFontSize]);

  const determineContrast = useCallback((hexColor: string) => {
    if (!hexColor) { return }

    /**
     * Check background color to determine if dark or light mode should be used
     */
    let [r, g, b] = hexColor.match(/\w\w/g)!.map(x => parseInt(x, 16));
    if ((r * 0.299 + g * 0.587 + b * 0.114) > 186) {
      setColorMode("light");
    } else {
      setColorMode("dark");
    }
  }, []);

  /**
   * Generate volume icon with gain indicator
   * @returns {JSX.Element} Volume icon element
   */
  const VolumeIcon = useCallback(() => {
    if (!globalVolume) { return <VolumeLowIcon/> }

    if (globalVolume > .8) {
      return <VolumeHighIcon/>
    } else if (globalVolume > .2) {
      return <VolumeMidIcon/>
    } else {
      return <VolumeLowIcon/>
    }
  }, [globalVolume]);

  // Should icons be displayed?
  useEffect(() => {
    if (deviceMenu?.length > 1) {
      setDisplayMenuIcon(true);
    } else {
      setDisplayMenuIcon(false);
    }
    if ((deviceLanguages as string[])?.length > 1) {
      setDisplayLanguageIcon(true);
    } else {
      setDisplayLanguageIcon(false);
    }
  }, [deviceLanguages, deviceMenu, isHome]);

  useEffect(() => {
    if (deviceData?.accessibility?.toggleFontSize) {
      setDisplayFontIcon(true);
    } else {
      setDisplayFontIcon(false);
    }

    if (deviceData?.accessibility?.showHomeIcon) {
      setDisplayHomeIcon(true);
    } else {
      setDisplayHomeIcon(false);
    }

    if (deviceData?.accessibility?.showContrastIcon) {
      setDisplayContrastIcon(true);
    } else {
      setDisplayContrastIcon(false);
    }

    if (deviceData?.accessibility?.showVolumeIcon) {
      setDisplayVolumeIcon(true);
    } else {
      setDisplayVolumeIcon(false);
    }
  }, [deviceData]);

  // Animate title fade when title is updated
  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.classList.remove("fadeIn");
      setTimeout(() => {
        titleRef.current.classList.add("fadeIn");
      }, 200);
    }
  }, [title])

  // Check background color to determine if dark or light mode should be used
  useEffect(() => {
    if (backgroundColor) {
      determineContrast(backgroundColor);
    }
  }, [backgroundColor, determineContrast]);

  if (hideHeader && !previewIsActive) {
    return null;
  }

  return (
    <>
      <Wrapper className={`${colorMode} ${previewIsActive ? "preview" : ""}`}>
        {(!isHome && !hideBackButton) && (
          <LeftButtonContainer>
            <Button onClick={goBack}><BackIcon/></Button>
            {displayHomeIcon && (<Button className={`${colorMode}`} onClick={goHome}><HomeIcon/></Button>)}
          </LeftButtonContainer>
        )}
        {title && (<Title className={`fadeIn${isHome ? " noBackButton" : ""}`}>{title}</Title>)}
        <RightButtonContainer>
          {displayLanguageIcon && (<Button className={`showInPreview ${colorMode}`} onClick={() => {setLanguagePopupOpen(true)}}><LanguageIcon/></Button> )}
          {displayContrastIcon && (<Button className={`showInPreview ${colorMode}`} onClick={toggleContrast}><ContrastIcon/></Button> )}
          {displayVolumeIcon && (<Button ref={volumeRef} className={`showInPreview ${colorMode}`} onClick={toggleVolume}><VolumeIcon/></Button> )}
          {displayFontIcon && (<Button className={`showInPreview ${colorMode}`} onClick={toggleFontSize}>{globalFontSize === 1 ? (<LargerFontIcon/>) : (<SmallerFontIcon/>)}</Button> )}
          {displayMenuIcon && (<Button className={`showInPreview ${colorMode}`} onClick={() => {setMenuOpen(true)}}><MenuIcon/></Button>)}
        </RightButtonContainer>
      </Wrapper>
      <Menu isOpen={menuOpen} close={() => {setMenuOpen(false)}}/>
      {displayVolumeControl && (<VolumeControl x={volumeRef?.current?.getBoundingClientRect()?.x + (volumeRef?.current?.getBoundingClientRect()?.width / 2)} y={volumeRef?.current?.getBoundingClientRect()?.y + volumeRef?.current?.getBoundingClientRect()?.height} close={() => { setDisplayVolumeControl(false) }}/>)}
      {displayLanguageIcon && (<LanguagePopup isOpen={languagePopupOpen} close={() => {setLanguagePopupOpen(false)}}/>)}
    </>
  );
}

export default Header;